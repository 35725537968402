.App {
  text-align: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  background-color: #2d2d2d; /* Dark background for the page */
  color: #f5f5f5; /* Light text for better readability on dark background */
  margin: 0;
  padding: 2px;
}

.App {
  max-width: 960px;
  margin: 0 auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #1a1a1a; /* Slightly lighter table background */
}

th {
  padding: 8px;
  border: 1px solid #444; /* Subtle borders for cells */
}

th {
  background-color: #333; /* Dark background for headers */
  color: #6dbf67; /* Green text for headers */
  text-align: center;
  font-weight: normal;
}

tbody tr:nth-of-type(odd) {
  background-color: #262626; /* Zebra striping for rows */
}

/* Additional styles for hovering over table rows */
tbody tr:hover {
  background-color: #333;
}

/* Styling for the table header to have a slight 3D effect */
thead tr {
  box-shadow: 0 2px 0 #000;
}

/* Light text for the table data for readability */
td {
  padding: 2px;
  border: 1px solid #444; /* Subtle borders for cells */
  /* color: #ddd; */
  font-size: 0.9em;
}

/* Adjusting the text size for better readability */
th {
  font-size: 0.95em;
}

.high {
  color: #75e375; /* Lighter green for good K/D ratios */
}

.low {
  color: #ff0000; /* Lighter green for good K/D ratios */
}

tbody tr:nth-of-type(1) td {
  color: gold; /* Set the text color of the first row in tbody to gold */
}

tbody tr:nth-of-type(2) td {
  /* color: silver; */
}

tbody tr:nth-of-type(3) td {
  /* color: #CD7F32; */
}

/* Add to existing styles */

.search-container input[type="text"],
.search-container select,
.search-container button {
  height: 35px; /* Ensure all elements have the same height */
  padding: 6px 6px; /* Adjust padding as needed */
  font-size: 14px; /* Standard font size for all elements */
  line-height: 1; /* Standard line height */
  border: 1px solid #ddd; /* Consistent border */
  background-color: #1a1a1a; /* Consistent background color */
  color: #fff; /* Consistent text color */
  border-radius: 4px; /* Rounded corners for aesthetics */
  margin: 0 5px 20px 0; /* Spacing between elements and from the table */
}

.search-summary {
  font-size: 14px;
  line-height: 1; /* Standard line height */
  color: #999; /* Consistent text color */
  /* margin-top: 2px; */
}

.search-container button {
  cursor: pointer; /* Change cursor to pointer on hover over button */
  background-color: #4CAF50; /* Different background color for button for distinction */
  color: #fff; /* Text color for button */
  border: none; /* Remove border for button */
}

/* Focus styles for input and select for better accessibility */
.search-container input[type="text"]:focus,
.search-container select:focus,
.search-container button:focus {
  outline: none;
  border-color: #6dbf67; /* Highlight color for focused element */
}

/* Hover effect for button for better user interaction feedback */
.search-container button:hover {
  background-color: #367c39; /* Slightly darker shade on hover */
}

input[type="text"] {
  height: 22px !important;
  padding: 2px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px; /* Optional: for rounded corners */
  background-color: #1a1a1a;
  color: #fff;
}

select {
  padding: 4px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 4px; /* Optional: for rounded corners */
  background-color: #1a1a1a;
  color: #fff;
}

select[title="type"] {
  cursor: pointer;
}

/* Style focus for input and select */
input[type="text"]:focus,
select:focus {
  outline: none;
  border-color: #6dbf67; /* Color to indicate focus */
}

/* Ensure that the input and select have the same height */
input[type="text"],
select {
  height: 40px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content .text-xs {list-style-type:none}
.modal-content table+button {margin:25px 0 0 0}

.modal-content button{
  background-color: #1a1a1a;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  height: 40px;
  line-height: 1.5;
  margin: 0;
  padding: 8px 12px;
}

.modal-content {
  width: 40%;
  background-color: #333;
  padding: 10px 10px 25px;
  overflow: auto; /* Allows scrolling if content overflows */
  position: relative; /* For positioning the close button or other elements inside */
}

.buildInfo {
  font-size: 8px;
  float: right;
}

.sticky-header th {
  position: sticky;
  top: 0;
  background-color: #ccc;
  color: #000;
  z-index: 1; /* Make sure the header is above other content */
}
